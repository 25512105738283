
.pg-thumbnail {
	width: 100%;
	max-width: 200px;
	margin-bottom: 1rem;
	box-shadow: 0 0 10px black;
}
.pg-thumbnail:hover {
    box-shadow: 0 0 10px orange;
}
